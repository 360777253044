<template>
  <v-container fluid class="fill-height login-component">
    <v-dialog v-model="dialog" persistent width="800">
      <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
          <v-toolbar-title>
            Форма скидання паролю
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              depressed
              @click="dialog = false"
              color="secondary darken-1"
          >Закрити
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-6">
          <v-form>
            <v-form ref="resetForm" v-model="resetForm.valid">
              <v-row>
                <v-col cols="12" sm="12" :md="resetForm.code_send ? 8 : 12" class="pb-0">
                  <v-text-field
                      v-model="resetForm.email"
                      type="email"
                      prepend-icon="mdi-email"
                      label="Email"
                      :rules="emailRules"
                      required
                      color="grey"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4" class="pb-0">
                  <v-text-field
                      v-if="resetForm.code_send"
                      v-model="resetForm.code"
                      type="email"
                      prepend-icon="mdi-code-equal"
                      label="Код доступу"
                      :rules="codeRules"
                      required
                      color="grey"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                  <v-text-field
                      v-if="resetForm.code_send"
                      v-model="resetForm.password"
                      type="password"
                      prepend-icon="mdi-lock"
                      label="Пароль"
                      :rules="[...passwordRulesRe]"
                      required
                      color="grey"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                  <v-text-field
                      v-if="resetForm.code_send"
                      v-model="resetForm.passwordRe"
                      type="password"
                      prepend-icon="mdi-lock"
                      label="Пароль (ще раз)"
                      :rules="[...passwordRulesRe, passwordResetConfirmationRule]"
                      required
                      color="grey"
                  />
                </v-col>
                <v-col cols="12">
                  <v-btn block depressed color="grey lighten-2" :disabled="!resetForm.valid"
                         @click.stop="reset_user_password_local">
                    {{ resetForm.code_send ? 'Змінити пароль' : 'Надіслати код підтвердження' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="10" md="6" class="resp">
        <div v-if="demo">
          <v-card
              max-width="850"
              class="mb-3">
            <v-card-text class="pa-2">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-list-item @click.stop="fillData('demo_base')">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="34" color="success">mdi-database</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="mb-2">Демонстраційна база</v-list-item-title>
                      <v-list-item-subtitle>
                        <div class="mb-1" style="display: flex; flex-wrap: wrap;">
                          <div class="font-weight-bold" style="flex: 0 0 70px">
                            Логін:
                          </div>
                          <div>{{ demo_data.demo_base.login }}</div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                          <div class="font-weight-bold" style="flex: 0 0 60px">
                            Пароль:
                          </div>
                          <div>{{ demo_data.demo_base.password }}</div>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-list-item @click.stop="fillData('demo_cabinet')">
                    <v-list-item-icon class="mr-4">
                      <v-icon size="34" color="success">mdi-monitor-dashboard</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="mb-2">Демо. персональний кабінет</v-list-item-title>
                      <v-list-item-subtitle>
                        <div class="mb-1" style="display: flex; flex-wrap: wrap;">
                          <div class="font-weight-bold" style="flex: 0 0 60px">
                            Логін:
                          </div>
                          <div>{{ demo_data.demo_cabinet.login }}</div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap">
                          <div class="font-weight-bold" style="flex: 0 0 60px">
                            Пароль:
                          </div>
                          <div>{{ demo_data.demo_cabinet.password }}</div>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <v-card
            max-width="850"
            style="background: linear-gradient(90deg, #005bbb1a 20%, #ffd5008f 100%);overflow: hidden;"
        >
          <img alt="#" :src="`${publicPath}img/icons/marka.jpg`"
               :class="$vuetify.breakpoint.smAndDown ? 'image-back-small' : ''"
               class="image-back">
          <v-card-title class="text-center justify-center" :class="$vuetify.breakpoint.mdAndUp ? 'pt-14 pb-12' : 'py-8'">
            <h1 class="font-weight-medium display-1 grey--text text--darken-2 login-component-title"
                style="position: relative"
            >
              <span style="color: rgb(80 80 80 / 72%)">Ласкаво просимо до</span> <br v-if="$vuetify.breakpoint.smAndDown">
              <span style="color: rgb(80 80 80 / 80%)">WeToo</span>
            </h1>
          </v-card-title>
          <v-tabs v-model="tab" background-color="transparent" color="grey darken-2" class="login-tabs"
                  :show-arrows="false"
                  :grow="$vuetify.breakpoint.smAndUp">
            <v-tab style="color: black; background-color: rgb(140 140 140 / 7%)">
              Вхід
            </v-tab>
            <v-tab style="color: black; background-color: rgb(140 140 140 / 7%)">
              Реєстрація в особистому кабінеті
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat @keyup.enter="login_user" class="pa-3">
                <v-card-text>
                  <v-form ref="authForm" v-model="authForm.valid">
                    <v-text-field
                        v-model="authForm.email"
                        type="email"
                        label="Email"
                        :rules="emailRules"
                        required
                        color="grey"
                        autocomplete="username"
                    />
                    <v-text-field
                        v-model="authForm.password"
                        type="password"
                        label="Пароль"
                        :rules="passwordRules"
                        autocomplete="current-password"
                        required
                        color="grey"
                    />
                    <a href="#" @click.stop.prevent="dialog = true">
                      Забули пароль?
                    </a>
                    <v-btn block depressed color="grey lighten-2"
                           class="mt-2" :disabled="!authForm.valid"
                           @click.stop="login_user"
                           style="position: relative"
                    >
                      <v-icon style="position:absolute; left: 5px; top: -2px" color="success">mdi-login-variant</v-icon>
                      Увійти
                    </v-btn>
                    <v-row class="mt-1" v-if="showGoogleAuth">
                      <v-col cols="12" md="12" class="py-1" v-if="showGoogleAuth">
                        <v-btn block depressed style="position: relative;"
                               color="grey lighten-2"
                               @click.stop="openGoogleUri"
                        >
                          <div style="position: absolute; left: 6px; top: 0;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                 aria-hidden="true">
                              <title>Google</title>
                              <g fill="none" fill-rule="evenodd">
                                <path fill="#4285F4"
                                      d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path>
                                <path fill="#34A853"
                                      d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path>
                                <path fill="#FBBC05"
                                      d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path>
                                <path fill="#EA4335"
                                      d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path>
                              </g>
                            </svg>
                          </div>
                          Увійти через Google
                        </v-btn>
                      </v-col>
                      <v-col cols="12" class="py-1 d-flex flex-nowrap">
                        <v-btn depressed block style="position: relative; min-width: auto !important;"
                               color="grey lighten-2"
                               tag="a"
                               href="https://t.me/Wetoo_bot"
                        >
                          <div style="position: absolute; left: 6px; top: 0;">
                            <svg style="height: 24px; width: 24px; fill: rgb(10,137,202);"
                                xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#44444" viewBox="0 0 20 20">
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                            </svg>
                          </div>
                          {{ $vuetify.breakpoint.smAndUp ? 'telegram bot (персональний помічник)' : 'telegram bot' }}
                        </v-btn>
                        <YouTubeButton
                            button-class="grey lighten-2"
                            icon-color="error lighten-1"
                            :icon-size="26"
                            :button-icon="false"
                            button-style="height: 36px; width: 42px; flex: 0 0 42px; margin-left: 4px;"
                            href="https://youtu.be/1m1u8VJrdc0"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat @keyup.enter="register_user_in_personal_cabinet" class="pa-3">
                <v-card-text>
                  <v-form ref="regForm" v-model="regForm.valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="8" class="pb-0">
                        <v-text-field
                            v-model="regForm.email"
                            type="email"
                            label="Email"
                            :rules="emailRules"
                            required
                            color="grey"
                            autocomplete="username"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4" class="pb-0">
                        <v-text-field
                            v-model="regForm.code"
                            type="email"
                            label="Код доступу"
                            :rules="codeRules"
                            required
                            color="grey"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                        <v-text-field
                            v-model="regForm.name"
                            type="email"
                            label="Прізвище, ім'я та по-батькові"
                            :rules="passwordRulesRe"
                            required
                            color="grey"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="pb-0 pt-0">
                        <v-text-field
                            v-model="regForm.password"
                            type="password"
                            label="Пароль"
                            :rules="[...passwordRulesRe]"
                            required
                            color="grey"
                            autocomplete="new-password"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-text-field
                            v-model="regForm.passwordRe"
                            type="password"
                            label="Пароль (ще раз)"
                            :rules="[...passwordRulesRe, passwordConfirmationRule]"
                            required
                            color="grey"
                            autocomplete="new-password"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                        <v-checkbox
                            v-model="regForm.privacy"
                            required
                            :rules="[v => !!v || 'Це поле є обов’язковим',]"
                        >
                          <template v-slot:label>
                            <div>
                              Я даю згоду на обробку
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <a
                                      target="_blank"
                                      :href="getPrivacyPath()"
                                      @click.stop
                                      v-on="on"
                                  >
                                    персональних даних
                                  </a>
                                </template>
                                Політика конфіденційності
                              </v-tooltip>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" class="d-flex flex-nowrap">
                        <v-btn block depressed color="grey darken-1 white--text mt-2"
                               style="position: relative; min-width: auto !important;"
                               :disabled="!regForm.valid"
                               @keypress.enter="register_user_in_personal_cabinet"
                               @click.stop="register_user_in_personal_cabinet">
                          Зареєструватися
                        </v-btn>
                        <YouTubeButton
                            button-class="grey lighten-2"
                            icon-color="error lighten-1"
                            :icon-size="26"
                            :button-icon="false"
                            button-style="height: 36px; width: 42px; flex: 0 0 42px; margin-left: 4px;"
                            href="https://youtu.be/ZhXRKuf76_U"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {AUTH_ERROR, AUTH_GOOGLE_REQUEST, AUTH_REQUEST} from "@/store/actions/auth";
import {mapGetters} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import userAPI from "@/utils/axios/user"
import { getGoogleUrl } from '@/utils/getGoogleUrl'
import {BASE_URL_AXIOS_LOCAL} from "@/utils/axios";
import {onBeforeUnload} from "@/utils/icons"

export default {
  name: "Login",
  components: {
    YouTubeButton: () => import("@/components/YouTubeButton.vue")
  },
  computed: {
    ...mapGetters(
        {
          personal_cab: 'getPersonalCabinetHome'
        }
    ),
    passwordConfirmationRule() {
      return this.regForm.password === this.regForm.passwordRe || 'Паролі не співвпадають'
    },
    passwordResetConfirmationRule() {
      return this.resetForm.password === this.resetForm.passwordRe || 'Паролі не співвпадають'
    }
  },
  data: () => ({
    from: 'http://localhost:8080/login',
    showGoogleAuth: true,
    tab: 0,
    dialog: false,
    demo: false,
    authForm: {
      email: '',
      password: '',
      valid: false
    },
    resetForm: {
      email: '',
      code: '',
      password: '',
      passwordRe: '',
      valid: false,
      code_send: false
    },
    regForm: {
      code: '',
      name: '',
      email: '',
      password: '',
      passwordRe: '',
      privacy: false,
      valid: false
    },
    loginRules: [
      v => !!v || 'Логін є обов’язковим',
      v => (v && v.length >= 4) || 'Мінімальна кількість символів 4',
    ],
    codeRules: [
      v => !!v || 'Код є обов’язковим',
      v => (v && v.length >= 10) || 'Мінімальна кількість символів 10',
    ],
    emailRules: [
      v => !!v || 'Це поле є обов’язковим',
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Введіть коректний Email'
      }
    ],
    passwordRules: [
      v => !!v || 'Пароль є обов’язковим',
      v => (v && v.length >= 4) || 'Мінімальна кількість символів 4',
    ],
    passwordRulesRe: [
      v => !!v || 'Це поле є обов’язковим',
      v => (v && v.length >= 4) || 'Мінімальна кількість символів 4',
    ],
    demo_data: {
      demo_base: {
        login: 'wetoo_test_base@ukr.net',
        password: 'wetoo070820'
      },
      demo_cabinet: {
        login: 'wetoo_personal@ukr.net',
        password: 'wetoo070820'
      }
    },
    google_auth: {
      active: false,
      token_id: '',
      error: ''
    },
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    openGoogleUri() {
      this.google_auth.active = false
      this.google_auth.token_id = ''
      this.google_auth.error = ''

      const from_url = `${this.getBasePath()}/login?api=${BASE_URL_AXIOS_LOCAL}`
      window.location.replace(getGoogleUrl(from_url))
    },
    getGoogleUrl,
    fillData(payload) {
      this.authForm.email = this.demo_data[payload].login
      this.authForm.password = this.demo_data[payload].password
      this.authForm.valid = true
    },
    login_user() {
      const {email, password} = this.authForm

      this.$refs.authForm.validate()
      if (!this.authForm.valid) {
        this.authForm.valid = false
        return
      }

      this.$store.dispatch(AUTH_REQUEST, {email, password})
          .then(() => {
            this.$router.push({name: 'afterLogin'}).catch(() => {})
          })
    },
    getBasePath() {
      return window.location.origin
    },
    getPrivacyPath() {
      return `${window.location.origin}/privacy-policy`
    },
    register_user_in_personal_cabinet() {
      const payload = {
        email: this.regForm.email,
        name: this.regForm.name,
        code: this.regForm.code,
        password: this.regForm.password,
        is_active: false,
        user_url: this.getBasePath()
      }

      this.$refs.regForm.validate()
      if (!this.regForm.valid) {
        this.regForm.valid = false
        return
      }

      userAPI.register_user_in_personal_cabinet(payload)
          .then(response => response.data)
          .then(data => {
            if (data.message_send) {
              this.$store.commit(ALERT_SHOW, {
                text: 'На вказану електронну пошту надіслано лист для завершення реєстрації',
                color: 'success'
              })
            } else {
              this.$store.commit(ALERT_SHOW, {
                text: 'Квартиру успішно додано до особистого кабінету користувача',
                color: 'success'
              })
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    reset_user_password_local() {
      const payload = {
        email: this.resetForm.email,
        code: this.resetForm.code,
        name: '',
        password: this.resetForm.password,
        is_active: false,
        user_url: this.getBasePath()
      }

      this.$refs.resetForm.validate()
      if (!this.resetForm.valid) {
        this.resetForm.valid = false
        return
      }

      if (!this.resetForm.code_send) {
        userAPI.send_reset_code(payload)
            .then(response => response.data)
            .then(data => {
              if (data.send_message) {
                this.$store.commit(ALERT_SHOW, {
                  text: 'На вказану електронну пошту надіслано код для скидання паролю',
                  color: 'success'
                })
                this.resetForm.code_send = true
              }
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      } else {
        userAPI.reset_user_password(payload)
            .then(response => response.data)
            .then(data => {
              if (!data.error) {
                this.$store.commit(ALERT_SHOW, {text: 'Пароль успішно змінений', color: 'success'})
                this.dialog = false

                this.authForm.email = this.resetForm.email
                this.authForm.password = this.resetForm.password

                this.resetForm.code_send = false
                this.resetForm.code = ''
                this.resetForm.email = ''
                this.resetForm.password = ''
                this.resetForm.passwordRe = ''
                this.resetForm.valid = true
              } else {
                this.$store.commit(ALERT_SHOW, {
                  text: 'Час коду для підтвердження скидання паролю завершився. Повторіть скидання паролю заново',
                  color: 'error'
                })
                this.resetForm.code_send = false
                this.resetForm.code = ''
              }
            })
      }
    }
  },
  watch: {
    dialog(payload) {
      if (!payload) {
        this.resetForm.code_send = ''
        this.resetForm.code = ''
        this.resetForm.email = ''
        this.resetForm.password = ''
        this.resetForm.passwordRe = ''
        this.resetForm.valid = false
      }
    }
  },
  created() {
    window.removeEventListener('beforeunload', onBeforeUnload)
    this.demo = this.$route.query.demo || false
    if (this.$route.query.client_token || this.$route.query.error) {
      this.google_auth.active = true
      this.google_auth.token_id = this.$route.query.client_token
      this.google_auth.error = this.$route.query.error

      if (this.google_auth.error) {
        this.$store.commit(ALERT_SHOW, {text: this.google_auth.error, color: 'error'})
        this.$router.replace({'query': null});
      } else {
        userAPI.google_auth({
          google_token: this.google_auth.token_id,
        })
            .then(response => response.data)
            .then(data => {
              this.$store.dispatch(AUTH_GOOGLE_REQUEST, data)
                  .then(() => {
                    this.$router.push({name: 'afterLogin'}).catch(() => {})
                  })
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.commit(AUTH_ERROR, err);
              localStorage.removeItem("user-token");
              this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-component-title {
  font-family: "MacPawFixelDisplay", "Roboto", sans-serif !important;
}
.image-back {
  height: 100%;
  position: absolute;
  top: -15%;
  width: 123%;
  left: -115px;
  mix-blend-mode: overlay;
  opacity: 0.6;
}
.image-back-small {
  width: 130%;
  left: -62px;
  top: -62px;
}
.vertical-logo {
  text-orientation: mixed;
  font-size: 29px;
  font-weight: 600;
  position: absolute;
  display: block;
  right: -30px;
  transform: rotate(90deg);
  top: -1px;
  color: #499e49;
}

@media only screen and (max-width: 1330px) and (min-width: 960px) {
  .resp {
    min-width: 70% !important;
    width: 70% !important;
  }
}
</style>
